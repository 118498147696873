import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentButton.figmaUrl.android} codeUrl={checklists.componentButton.codeUrl.android} checklists={checklists.componentButton.checklists} mdxType="ChecklistResourcesSection" />
    <p>{`Buttons are commonly used in user interfaces to initiate an action or prompt the user to open a link. They can contain a combination of clear and concise labels as well as icons to provide additional context.
Legion provides a variety of options to customize your buttons to match your desired aesthetic and functionality.`}</p>
    <h2>{`Variants`}</h2>
    <p>{`Legion has three color themes:`}</p>
    <ol>
      <li parentName="ol">{`Color Primary Theme.`}</li>
      <li parentName="ol">{`Color Secondary Theme.`}</li>
      <li parentName="ol">{`Color Error Theme.`}</li>
    </ol>
    <p>{`Each theme has four variants:`}</p>
    <ol>
      <li parentName="ol">{`Fill.`}</li>
      <li parentName="ol">{`Ghost.`}</li>
      <li parentName="ol">{`Outline.`}</li>
      <li parentName="ol">{`Transparent.`}</li>
    </ol>
    <p>{`Additionally, there are four sizes available on Legion :`}</p>
    <ol>
      <li parentName="ol">{`Large.`}</li>
      <li parentName="ol">{`Fit.`}</li>
      <li parentName="ol">{`Slim.`}</li>
      <li parentName="ol">{`Small.`}</li>
    </ol>
    <p>{`Legion also has four loading states:`}</p>
    <ol>
      <li parentName="ol">{`Loading Status Fill.`}</li>
      <li parentName="ol">{`Loading Ghost Status.`}</li>
      <li parentName="ol">{`Loading Status Framework.`}</li>
      <li parentName="ol">{`Loading Transparent Status.`}</li>
    </ol>
    <p>{`Finally, Legion provides four disabled states:`}</p>
    <ol>
      <li parentName="ol">{`Disabled State Fill.`}</li>
      <li parentName="ol">{`Disabled State Ghost.`}</li>
      <li parentName="ol">{`Disabled State Outline`}</li>
      <li parentName="ol">{`Disabled State Transparent.`}</li>
    </ol>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": 250,
            "height": 500,
            "src": "https://teddys-minio-teddys-dev.vsan-apps.playcourt.id/legion/component/button/android_button.gif",
            "alt": "Button Legion android"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <br />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`To implement Button, you can use this tag “Lgn{`}<strong parentName="p"><em parentName="strong">{`Theme`}</em></strong>{`}{`}<strong parentName="p"><em parentName="strong">{`Variant`}</em></strong>{`}Button”`}</p>
    <h3>{`Color Primary Theme`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.fill.LgnPrimaryLargeButton
    // define your attribute's here
    ... />

`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryLargeButton(requiredContext()).apply {
    //Your View's customization here
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />{" "}
    <h3>{`Color Secondary Theme`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.fill.LgnSecondaryLargeButton
    // define your attribute's here
    ... />


`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSecondaryLargeButton(requiredContext()).apply {
    //Your View's customization here
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />{" "}
    <h3>{`Color Error Theme`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.fill.LgnErrorLargeButton
    // define your attribute's here
    ... />


`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnErrorLargeButton(requiredContext()).apply {
    //Your View's customization here
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />{" "}
    <h3>{`Fill Button Variant`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.fill.LgnPrimaryLargeButton
    // define your attribute's here
    ... />

`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryLargeButton(requiredContext()).apply {
    //Your View's customization here
}



...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />{" "}
    <h3>{`Ghost Button Variant`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.ghost.LgnPrimaryGhostLargeButton
    // define your attribute's here
    ... />


`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryGhostLargeButton(requiredContext()).apply {
    //Your View's customization here
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`Outline Button Variant`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.outline.LgnPrimaryOutlineLargeButton
    // define your attribute's here
    ... />



`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryOutlineLargeButton(requiredContext()).apply {
    //Your View's customization here
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`Transparant Button Variant`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.transparent.LgnPrimaryTransparentLargeButton
    // define your attribute's here
    ... />




`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryTransparentLargeButton(requiredContext()).apply {
    //Your View's customization here
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`Large Button`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.fill.LgnPrimaryLargeButton
    // define your attribute's here
    ... />





`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryLargeButton(requiredContext()).apply {
    //Your View's customization here
}


...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`Fit Button`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.fill.LgnPrimaryFitButton
    // define your attribute's here
    ... />


`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryFitButton(requiredContext()).apply {
    //Your View's customization here
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`Slim Button`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.fill.LgnPrimarySlimButton
    // define your attribute's here
    ... />


`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimarySlimButton(requiredContext()).apply {
    //Your View's customization here
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`Small Button`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.fill.LgnPrimarySmallButton
    // define your attribute's here
    ... />


`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimarySmallButton(requiredContext()).apply {
    //Your View's customization here
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`Loading State Fill Variant`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.fill.LgnPrimaryLargeButton
    ...
    app:isLoading="true"
    ...
    />


`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryLargeButton(requiredContext()).apply {
    isLoading = true
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`Loading State Ghost Variant`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.ghost.LgnPrimaryGhostLargeButton
    ...
    app:isLoading="true"
    ...
    />


`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryGhostLargeButton(requiredContext()).apply {
    isLoading = true
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`Loading State Outline Variant`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.outline.LgnPrimaryOutlineLargeButton
    ...
    app:isLoading="true"
    ...
    />


`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryOutlineLargeButton(requiredContext()).apply {
    isLoading = true
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`Loading State Transparant Variant`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.transparent.LgnPrimaryTransparentLargeButton
    ...
    app:isLoading="true"
    ...
    />


`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryTransparentLargeButton(requiredContext()).apply {
    isLoading = true
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`Disable State Fill Variant`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.fill.LgnPrimaryLargeButton
    ...
    android:enabled="false"
    ...
    />


`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryLargeButton(requiredContext()).apply {
    isEnabled = false
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`Disabled State Ghost Variant`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.ghost.LgnPrimaryGhostLargeButton
    ...
    android:enabled="false"
    ...
    />


`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryGhostLargeButton(requiredContext()).apply {
    isEnabled = false
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`Disabled State Outline Variant`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.outline.LgnPrimaryOutlineLargeButton
    ...
    android:enabled="false"
    ...
    />


`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryOutlineLargeButton(requiredContext()).apply {
    isEnabled = false
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`Disabled State Transparent Variant`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.button.transparent.LgnPrimaryTransparentLargeButton
    ...
    android:enabled="false"
    ...
    />


`}</code></pre>
    <h4>{`Programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryTransparentLargeButton(requiredContext()).apply {
    isEnabled = false
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set text value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text Size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:textSize`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`textSize`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set text size value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Enable or Disable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:enabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isEnable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To enable or disable button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Show Loading`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:isLoading`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isLoading`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To show loading progress in button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Start Icon Drawable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:startIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`startIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Show icon on start position button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`End Icon Drawable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:endIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`endIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Show icon on end position button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Color Set`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:colorSet`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`colorSet`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To change color set from button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Click Listener`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setOnClickListener(onClick: (View) -> Unit)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Listener view when button has been pressed`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      